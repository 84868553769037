const content = {
  title: 'Absicherung für Mitarbeiter',
  headvisualHeadline: 'Absicherung für Mitarbeiter',
  sectionHead: {
    headline: 'Machen Sie die Absicherung Ihrer Mitarbeiter zur Chefsache!',
    paragraph:
      'Ein attraktiver Arbeitgeber? Wer möchte das nicht sein in Zeiten des Fachkräftemangels! Schließlich sind loyale, motivierte und gesunde Arbeitnehmer wichtig für den Unternehmenserfolg. Heben Sie sich mit wertvollen Zusatzleistungen vom Arbeitsmarkt ab. Mit betrieblichen Vorsorgemodellen als Zusatzleistung steigern Sie Ihre Attraktivität als Arbeitgeber und haben bessere Chancen neue Mitarbeiter für sich zu gewinnen. Außerdem zeigen Sie sich als verantwortungsvoller Arbeitgeber und ermöglichen Ihren Mitarbeitern eine umfassende Absicherung.'
  },
  cards: [
    {
      background: 'light-blue',
      headline: 'Betriebliche Altersvorsorge (bAV)',
      paragraph:
        'Mit einer bAV bieten Sie Ihren Mitarbeitern mehr: Eine Vorsorgelösung fürs Alter.',
      listCols: 2,
      listItems: [
        {
          href:
            '/unsere-loesungen/absicherung-fuer-mitarbeiter/betriebliche-altersvorsorge/bav-direktversicherung',
          text: 'Direktversicherung'
        },
        {
          href:
            '/unsere-loesungen/absicherung-fuer-mitarbeiter/betriebliche-altersvorsorge/bav-unterstuetzungskasse',
          text: 'Unterstützungskasse'
        },
        {
          href:
            '/unsere-loesungen/absicherung-fuer-mitarbeiter/betriebliche-altersvorsorge/bav-pensionszusage',
          text: 'Pensionszusage'
        },
        {
          href:
            '/unsere-loesungen/absicherung-fuer-mitarbeiter/betriebliche-altersvorsorge/bav-pensionsfonds',
          text: 'Pensionsfonds'
        }
      ]
    },
    {
      background: 'light-blue',
      headline: 'Betrieblicher Einkommensschutz (EKS)',
      paragraph:
        'Mit dem betrieblichen Einkommensschutz sichern Sie das größte Kapital Ihrer Mitarbeiter – die Arbeitskraft.',
      listItems: [
        {
          href:
            '/unsere-loesungen/absicherung-fuer-mitarbeiter/betrieblicher-einkommensschutz/eks-berufsunfaehigkeit',
          text: 'Berufsunfähigkeitsversicherung'
        },
        {
          href:
            '/unsere-loesungen/absicherung-fuer-mitarbeiter/betrieblicher-einkommensschutz/eks-grundfaehigkeitsversicherung',
          text: 'Grundfähigkeitsversicherung'
        }
      ]
    },
    {
      background: 'light-blue',
      headline: 'Betriebliche Kranken&shy;versicherung (bKV)',
      paragraph:
        'Sichern Sie Ihren Mitarbeitern eine bessere Gesundheitsversorgung zu günstigen Beiträgen.',
      link: {
        href:
          '/unsere-loesungen/absicherung-fuer-mitarbeiter/betriebliche-krankenversicherung',
        text: 'Mehr erfahren'
      }
    },
    {
      background: 'light-blue',
      headline: 'Weitere Absicherungs&shy;möglichkeiten',
      paragraph:
        'Hier finden Sie weitere Absicherungsmöglichkeiten für Ihre Mitarbeiter.',
      link: {
        href:
          '/unsere-loesungen/absicherung-fuer-mitarbeiter/weitere-absicherungsmoeglichkeiten',
        text: 'Mehr erfahren'
      }
    }
  ]
};

export default content;
