import React from 'react';
import { graphql, Link } from 'gatsby';

import MainLayout from 'src/layout/MainLayout';
import { SectionHead, Grid, List } from 'src/components';
import { CardWithBackground } from 'src/components/Cards';

import content from 'src/content/unsere-loesungen/absicherung-fuer-mitarbeiter';
import subpageNavigations from 'src/data/subpage-navigations';

const AbsicherungFuerMitarbeiterPage = ({ data }) => (
  <MainLayout
    title={content.title}
    headvisual={{
      image: data.headvisual,
      headline: content.headvisualHeadline,
      position: '75'
    }}
    subpageNavItems={subpageNavigations.absicherungFuerMitarbeiter}
  >
    <section>
      <SectionHead headline={content.sectionHead.headline}>
        <p>{content.sectionHead.paragraph}</p>
      </SectionHead>
      <Grid columns={2}>
        {content.cards.map((card, index) => (
          <CardWithBackground
            key={index}
            image={data[`cardBackground${index + 1}`]}
            background={card.background}
            headline={card.headline}
          >
            <p>{card.paragraph}</p>
            {card.link && (
              <Link
                to={card.link.href}
                className="arrow-link arrow-gray-blue text-gray-blue"
              >
                {card.link.text}
              </Link>
            )}
            {card.listItems && (
              <div className="text-gray-blue font-bold">
                <List columns={card.listCols} items={card.listItems} />
              </div>
            )}
          </CardWithBackground>
        ))}
      </Grid>
    </section>
  </MainLayout>
);

export default AbsicherungFuerMitarbeiterPage;

export const query = graphql`
  {
    headvisual: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "man-looking-backwards-wide.jpg" }
    ) {
      ...image
    }
    cardBackground1: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "man-with-jeansshirt-and-wrist-watch.jpg" }
    ) {
      ...image
    }
    cardBackground2: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "group-of-five-people.jpg" }
    ) {
      ...image
    }
    cardBackground3: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "blond-woman-smiles.jpg" }
    ) {
      ...image
    }
    cardBackground4: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "man-typing-on-tablet.jpg" }
    ) {
      ...image
    }
  }
`;
